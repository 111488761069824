import { Action, action, persist } from "easy-peasy"

import { getUserID } from "../utils/utils"

type UserType = {
	name: string
	email: string
	phone: string
	address: string
	city: string
	country: string
	birthDate: string
	admin: number
	id: string
	psc: string
}

interface IUserModel {
	currentUser: UserType
	userID: string
	token: string
	isAuthenticated: boolean
	isAuthorized: boolean
	setUser: Action<this, UserType>
	setToken: Action<this, string>
	logout: Action<this>
}

export interface StoreModel {
	user: IUserModel
}

const userModel: IUserModel = {
	currentUser: {
		name: "",
		email: "",
		phone: "",
		address: "",
		city: "",
		country: "",
		birthDate: "",
		admin: 0,
		id: "",
		psc: "",
	},
	userID: "",
	token: "",
	isAuthenticated: false,
	isAuthorized: false,
	setUser: action((state, payload) => {
		state.currentUser = payload
	}),
	setToken: action((state, payload) => {
		state.userID = getUserID(payload)
		state.token = payload
		state.isAuthorized = true
		state.isAuthenticated = true
		localStorage.setItem("accessToken", payload)
	}),
	logout: action((state) => {
		state.currentUser = {
			name: "",
			email: "",
			phone: "",
			address: "",
			city: "",
			country: "",
			birthDate: "",
			admin: 0,
			id: "",
			psc: "",
		}
		state.userID = ""
		state.token = ""
		state.isAuthorized = false
		state.isAuthenticated = false
		localStorage.removeItem("accessToken")
	}),
}

export const storeModel: StoreModel = {
	user: persist(userModel, { storage: "localStorage" }),
}
