import jwt_decode from "jwt-decode"

// Types
import { orderStatusType } from "../types/price-offer.types"

interface IToken {
  ADVISION: object
  exp: number
  iat: number
  sub: string
}

export const checkIfTokenValid = (token: string) => {
  if (!token) return false
  console.log("TOKEN VERIFICATION START")
  const dToken = jwt_decode<IToken>(token)
  if (Date.now() >= dToken.exp * 1000) {
    console.log("TOKEN IS EXPIRED")
    return false
  }
  return true
}

export const getUserID = (token: string) => {
  if (!token) return ""
  const dToken = jwt_decode<IToken>(token)
  return dToken.sub
}

export const formatPrice = (price: number, convert: boolean = true) => {
  if (!price) return "0.00€"
  if (convert) return `${(price / 100).toFixed(2)}€`
  if (!convert) return `${price}€`
}

export const formatSize = (size: number) => {
  if (!size) return "0 m2"
  return `${(size / 100).toFixed(2)} m2`
}

export const getCalculatorSlug = (route: string) => {
  if (!route) return ""
  switch (route) {
    case "plumbing":
      return "plumbing"
    case "underfloorHeating":
      return "underfloor-heating"
    case "sourceOfHeat":
      return "source-of-heat"
    case "boiler":
      return "boiler"
    case "wiring":
      return "wiring"
    case "solarPanel":
      return "solar-panel"
    case "ventilation":
      return "ventilation"
    default:
      return ""
  }
}

export const getCalculatorName = (string: string) => {
  if (!string) return ""
  switch (string) {
    case "plumbing":
      return "Vodoinštalácia a odpady"
    case "underfloorHeating":
      return "Podlahové kúrenie"
    case "sourceOfHeat":
      return "Tepelné čerpadlo"
    case "boiler":
      return "Kotol"
    case "wiring":
      return "Elektroinštalácie"
    case "solarPanel":
      return "Obnoviteľné zdroje"
    case "ventilation":
      return "Chladenie"
    default:
      return ""
  }
}

export const formatDate = (timestamp: Date) => {
  const formatedDate = new Date(Number(timestamp)).toLocaleDateString("sk-SK", {
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  })

  return formatedDate
}

export const formatRoomType = (type: string) => {
  switch (type) {
    case "obyvacka":
      return "obývačka"
    case "kuchyna":
      return "kuchyňa"
    case "chodba":
      return "chodba"
    case "technicka":
      return "technická miestnosť (kotolňa)"
    case "pracovna":
      return "práčovňa"
    case "kupelna":
      return "kúpelňa"
    case "spalna":
      return "izba - spálňa"
    case "detska":
      return "izba - detská"
    case "izba":
      return "izba - (nezaleží)"
    case "predsien":
      return "predsieň"
    case "garaz":
      return "garáž"
    case "komora":
      return "komora (špajza)"
    case "satnik":
      return "šatník"
    case "wc":
      return "WC"
    case "schodisko":
      return "schodisko"
    case "balkon":
      return "balkón"
    case "terasa":
      return "terasa"
    case "podkrovie":
      return "podkrovie / povala"
    case "fitness":
      return "fitness"
    case "sklad":
      return "sklad"
    case "oddychova":
      return "oddychová miestnosť"
    case "hobby":
      return "hobby miestnosť"
    case "kniznica":
      return "knižnica"
    case "lkuchyna":
      return "letná kuchynka"
    case "zzahrada":
      return "zimná záhrada"
    case "sauna":
      return "sauna"
    default:
      return ""
  }
}

export const formatRoofType = (type: string) => {
  switch (type) {
    case "A":
      return "Áčková"
    case "R":
      return "Rovná"
    default:
      return ""
  }
}

export const formatStatus = (type: orderStatusType) => {
  switch (type) {
    case "CREATED":
      return "Nová"
    case "INPROGRESS":
      return "Spracovaná"
    case "SEND":
      return "Odoslaná"
    case "WIN":
      return "Vyhratá"
    case "LOSS":
      return "Prehratá"
    default:
      return ""
  }
}
