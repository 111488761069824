import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import {
  createHttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

import { StoreProvider } from "easy-peasy";
import NotificationsProvider from "./components/notifications/notifications.context";
import { store } from "./store/store";

import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APOLLO_URI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("accessToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      "x-forwarded-key": process.env.REACT_APP_UPLOAD_API_XF ?? "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <StoreProvider store={store}>
        <NotificationsProvider>
          <App />
        </NotificationsProvider>
      </StoreProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
