import Spinner from "../spinner/spinner.component"

import { Container } from "./loading-fallback.styles"

const LoadingFallback = () => {
	return (
		<Container>
			<Spinner />
		</Container>
	)
}

export default LoadingFallback
