import { createContext, useContext, useState, useEffect } from "react"

// Hooks
import { useDebounce } from "usehooks-ts"

// Types
import {
	INotificationsContext,
	INotification,
	INotificationsActionType,
} from "./notifications.types"

const NotificationsContext = createContext<INotificationsContext>({
	notifications: [],
	pushNotification: () => {},
})

export const useNotificationsContext = () => useContext(NotificationsContext)

const NotificationsProvider = ({ children }: { children: React.ReactNode }) => {
	const [notifications, setNotifications] = useState<INotification[]>([])

	const pushNotification = (
		action: INotificationsActionType,
		text?: string
	) => {
		setNotifications((prevValue) => [
			...prevValue,
			{
				action,
				text,
			},
		])
	}

	const debouncedNotifications = useDebounce<typeof notifications>(
		notifications,
		3000
	)

	useEffect(() => {
		if (!debouncedNotifications.length) return
		const arr = debouncedNotifications
		arr.shift()
		setNotifications([...arr])
	}, [debouncedNotifications, setNotifications])

	return (
		<NotificationsContext.Provider
			value={{
				notifications,
				pushNotification,
			}}
		>
			{children}
		</NotificationsContext.Provider>
	)
}

export default NotificationsProvider
