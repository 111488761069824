import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    scroll-behavior: smooth;
    scroll-snap-type: y proximity;
    font-size: 62.5%;
  }
  body {
    line-height: 1.5;
    font-size: 1.6rem;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.fontPrimary};
    font-family: 'Raleway', sans-serif;
    line-height: 1.5;
  }

  h1 {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.9rem;
    /* font-weight: 600; */
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primary};
  }
  ul, ol {
    list-style: none;
  }
  input, button {
    border: none;
    outline: none;
    font-size: inherit;
    font-family: inherit;
    &:focus {
      outline: none;
    }
  }
`
