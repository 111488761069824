// Utils
import ReactDOM from "react-dom"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

// Icons
import { AiFillCheckCircle } from "react-icons/ai"

// Hooks
import { useList, useDebounce } from "react-use"

interface IProps {
	notifications: {
		action: string
		text?: string
	}[]
}

export const useNotificationModal = () => {
	const [notifications, { push, removeAt }] = useList<{
		action: "" | "ERROR" | "SUCCESS" | "CHYBA" | "ÚSPEŠNÉ"
		text: string
	}>([])

	const pushNotification = (
		action: "" | "ERROR" | "SUCCESS" | "CHYBA" | "ÚSPEŠNÉ",
		text: string
	) => {
		push({
			action,
			text,
		})
	}

	useDebounce(
		() => {
			if (!notifications.length) return
			removeAt(0)
		},
		5000,
		[notifications]
	)

	return {
		notifications,
		pushNotification,
	}
}

const NotificationModal: React.FC<IProps> = ({ notifications }) => {
	const el: HTMLElement = document.getElementById("portal")!

	return ReactDOM.createPortal(
		<ModalContainer>
			<AnimatePresence>
				{notifications.map((i, idx) => (
					<NotificationContainer
						key={idx}
						initial={{
							opacity: 0,
							x: 100,
						}}
						animate={{
							opacity: 1,
							x: 0,
						}}
						exit={{
							opacity: 0,
						}}
						action={i.action}
					>
						<h2>
							{i.action === "ÚSPEŠNÉ" || i.action === "SUCCESS" ? (
								<figure>
									<AiFillCheckCircle />
								</figure>
							) : (
								i.action
							)}
						</h2>
						<p>{i.text}</p>
					</NotificationContainer>
				))}
			</AnimatePresence>
		</ModalContainer>,
		el
	)
}

export default NotificationModal

const ModalContainer = styled(motion.div)`
	position: fixed;
	max-width: 45rem;
	width: 100%;
	bottom: 3rem;
	right: 3rem;
	padding: 1.5rem;
	z-index: 999;
`

const NotificationContainer = styled(motion.div)<{ action: string }>`
	max-width: 35rem;
	width: 100%;
	padding: 1.5rem;
	box-shadow: 0.3rem 0 1rem 0.5rem rgba(0, 0, 0, 0.05);
	background-color: ${({ theme }) => theme.backgroundPrimary};
	display: flex;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	h2 {
		color: ${({ action, theme }) =>
			action === "SUCCESS" || action === "ÚSPEŠNÉ" ? theme.green : theme.red};
		margin-right: 1.5rem;
	}

	figure {
		font-size: 5rem;
	}
`
