import { useState } from "react"

export const useTheme = () => {
	const [currentTheme, setCurrentTheme] = useState(
		localStorage.getItem("theme") || "light"
	)

	const toggleTheme = () => {
		if (currentTheme === "light") {
			localStorage.setItem("theme", "dark")
			setCurrentTheme("dark")
			return
		}
		if (currentTheme === "dark") {
			localStorage.setItem("theme", "light")
			setCurrentTheme("light")
			return
		}
	}

	return {
		currentTheme,
		toggleTheme,
	}
}
