import React, { Suspense, lazy, useEffect } from "react"
import { HashRouter, Switch, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import LoadingFallback from "./components/loading-fallback/loading-fallback.component"
// import ThemeSwitcher from "./components/theme-switcher/theme-switcher.component"
import { useTheme } from "./hooks/useTheme.hook"
import { GlobalStyles } from "./styles/global.styles"
import { darkTheme, lightTheme } from "./styles/theme.styles"
import ProtectedRoute from "./components/protected-route/protected-route.component"
import { useStoreRehydrated } from "easy-peasy"
import { useStoreActions, useStoreState } from "./store/hooks"
import { checkIfTokenValid } from "./utils/utils"
import NotificationModal from "./components/notification-modal/notification-modal.component"
import { useNotificationsContext } from "./components/notifications/notifications.context"

const SignInPage = lazy(() => import("./pages/sign-in/sign-in.page"))
const DashboardPage = lazy(() => import("./pages/dashboard/dashboard.page"))

const App = () => {
  const rehydrated = useStoreRehydrated()
  const { token } = useStoreState((state) => state.user)
  const { logout } = useStoreActions((actions) => actions.user)
  const { notifications } = useNotificationsContext()

  const {
    currentTheme,
    // toggleTheme
  } = useTheme()

  const theme = currentTheme === "light" ? lightTheme : darkTheme

  useEffect(() => {
    if (!rehydrated) return
    const isValid = checkIfTokenValid(token)
    if (isValid) return
    logout()
  }, [rehydrated, token, logout])

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {rehydrated && (
          <Suspense fallback={<LoadingFallback />}>
            <HashRouter basename="/">
              <Switch>
                <Route exact path="/" component={SignInPage} />
                <ProtectedRoute path="/admin" component={DashboardPage} />
              </Switch>
              <NotificationModal notifications={notifications} />
            </HashRouter>
          </Suspense>
        )}
        {/* <ThemeSwitcher handleClick={toggleTheme} /> */}
      </ThemeProvider>
    </React.Fragment>
  )
}

export default App
