import {
	Route,
	Redirect,
	RouteProps,
	RouteComponentProps,
} from "react-router-dom"
import { useStoreState } from "../../store/hooks"

interface IRouteProps extends RouteProps {
	component: React.FC<RouteComponentProps>
}

const ProtectedRoute: React.FC<IRouteProps> = ({
	component: Component,
	...otherProps
}) => {
	const { isAuthenticated } = useStoreState((state) => state.user)

	return (
		<Route
			{...otherProps}
			render={(props) => {
				if (isAuthenticated) {
					return <Component {...props} />
				} else {
					return <Redirect to="/" />
				}
			}}
		/>
	)
}

export default ProtectedRoute
